<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect">
		<el-table-column type="selection" width="55" v-if="false">
		</el-table-column>
		<el-table-column label="站点" prop="channel" min-width="120">
		</el-table-column>
		<el-table-column label="国家" prop="countryCode" min-width="100">
		</el-table-column>
		<el-table-column label="语言" prop="languageCode" min-width="100">
		</el-table-column>
		<el-table-column label="品类" prop="categoryCode" min-width="100">
		</el-table-column>
		<el-table-column label="BID" prop="bid" min-width="100">
		</el-table-column>
		<el-table-column label="ROAS" prop="roas" min-width="100">
		</el-table-column>
		<el-table-column label="Title" min-width="200">
			<template slot-scope="scope">
				<div v-for="title in scope.row.titles">
					<small>{{title}}</small>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="Primary Text" min-width="200">
			<template slot-scope="scope">
				<div v-for="primaryText in scope.row.primaryTexts">
					<small>{{primaryText}}</small>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="发布类型" prop="publishType" min-width="150">
		</el-table-column>
		<el-table-column label="发布状态" min-width="150">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
				<el-button type="text" @click="$emit('editRow', scope.row, true)">复制</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		}
	}
</script>

<style>
</style>
