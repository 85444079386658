<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="false" :multiple="false" :local="true" v-model="queryForm.channel" :parent="false" @onload="q.channel=queryForm.channel;getList()" :clearable="false"></form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="form.channel=queryForm.channel;visible.add=true">添加配置</el-button>
				</form-item>
			</template>
			<template #advanced>
				<form-item-countries v-model="queryForm.country" :site="queryForm.channel"></form-item-countries>
				<form-item label="语言:" style="width:200px;">
					<el-select v-model="queryForm.language"  size="mini" clearable filterable>
						<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
					</el-select>
				</form-item>
				<form-item label="品类:" style="width:200px;">
					<el-input v-model.trim="queryForm.categoryCode" @input="queryForm.categoryCode=queryForm.categoryCode.toUpperCase()" size="mini" placeholder="请输入"></el-input>
				</form-item>
				<form-item-publishtypes v-model="queryForm.publishType" clearable></form-item-publishtypes>
				<form-item label="发布状态:" style="width:230px;">
					<el-select v-model="queryForm.active" size="mini" clearable>
						<el-option label="开启" :value="true"></el-option>
						<el-option label="暂停" :value="false"></el-option>
					</el-select>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="active">发布</el-dropdown-item>
			        <el-dropdown-item command="deactive">取消发布</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<context-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @changePublish="changeActive" @editRow="editRow"></context-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" width="1000px">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item-sites :abbr="false" v-model="form.channel" label-width="60px" prop="channel"></el-form-item-sites>
					</el-col>
					<el-col :span="12">
						<el-form-item-countries v-model="form.countryCode" label-width="80px" :site="form.channel" size="medium" :clearable="false"></el-form-item-countries>
					</el-col>
					<el-col :span="4"></el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="8">
						<el-form-item label="语言:" label-width="60px" prop="languageCode">
							<el-select v-model="form.languageCode" filterable class="w-100">
								<el-option v-for="language in shortLanguages" :label="language" :value="language"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="品类:" label-width="60px" prop="categoryCode">
							<el-input v-model.trim="form.categoryCode" placeholder="请输入，全部适用请输入ALL" @input="form.categoryCode=form.categoryCode.toUpperCase()"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="发布类型:" label-width="100px">
							<form-item-publishtypes :with-layout="false" v-model="form.publishType"></form-item-publishtypes>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="12">
						<el-form-item label="BID:" label-width="60px">
							<el-input class="w-100" :value="form.bid" @input="bidChanged" placeholder="BID: 1.00~100.00"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="ROAS:" label-width="60px">
							<el-input class="w-100" :value="form.roas" @input="roasChanged" placeholder="ROAS: 0.000~10.000"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="10">
					<el-col :span="12">
						<el-form-item v-for="(item, index) in form.titles" :prop="`titles.${index}.title`" :label="`Title ${index + 1}`" label-width="80px" :rules="[{required: true, message: '标题不能为空'}, {validator: (rule, value, callback) => {value.trim().length === 0 ? callback('标题不能为空') : callback();}}]">
							<el-input type="textarea" v-model="item.title" placeholder="请输入" style="width:calc(100% - 100px)"></el-input>
							<el-button v-if="index===0" class="ml-2" style="border:1px dashed;width:60px;" size="mini" @click="form.titles.push({title:''})">+添加</el-button>
							<el-button v-else type="text" class="text-danger" @click="form.titles.splice(index, 1)" style="width:60px;">删除</el-button>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item v-for="(item, index) in form.primaryTexts" :label="`Primary Text ${index + 1}`" :prop="`primaryTexts.${index}.primaryText`" label-width="115px" :rules="[{required: true, message: '内容不能为空'}, {validator: (rule, value, callback) => {value.trim().length === 0 ? callback('内容不能为空') : callback();}}]">
							<el-input type="textarea" v-model="item.primaryText" placeholder="请输入" style="width:calc(100% - 70px)"></el-input>
							<el-button v-if="index===0" class="ml-2" style="border:1px dashed;width:60px;" size="mini" @click="form.primaryTexts.push({primaryText:''})">+添加</el-button>
							<el-button v-else type="text" class="text-danger" @click="form.primaryTexts.splice(index, 1)" style="width:60px;">删除</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapActions
	} from 'vuex'
	import ContextTable from '@/components/contents/context-table.vue'
	import config from './minixs/index.js'
	import Lodash from 'lodash'
	const InitialForm = {
		channel: '',
		countryCode: '',
		languageCode: '',
		categoryCode: '',
		publishType: 'Normal',
		titles: [{title: ''}],
		primaryTexts: [{primaryText: ''}],
		bid: '',
		roas: ''
	}
	export default {
		mixins:[config],
		components: {
			ContextTable
		},
		data() {
			return {
				queryForm: {
					channel: '',
					country: '',
					language: '',
					category: '',
					publishType: '',
					active: ''
				},
				tableData: [],
				q: {
					channel: ''
				},
				visible: {
					add: false
				},
				categories: [],
				form: Lodash.cloneDeep(InitialForm),
				rules: {
					channel: [{
						required: true,
						message: '站点不能为空'
					}],
					countryCode: [{
						required: true,
						message: '国家不能为空'
					}],
					languageCode: [{
						required: true,
						message: '语言不能为空'
					}],
					categoryCode: [{
						required: true,
						message: '品类不能为空'
					}]
				},
				fetchOnCreated: false,
				namespace: 'fb-contents-context'
			}
		},
		mounted() {
			this.getShortLanguages()
		},
		computed: {
			...mapState({
				shortLanguages: state => state.content.shortLanguages
			})
		},
		methods: {
			...mapActions([
				'getShortLanguages',
			]),
			// Override
			prepareFormData() {
				const { titles, primaryTexts } = this.form
				return {...this.form, titles: titles.map(v => v.title), primaryTexts: primaryTexts.map(v => v.primaryText)}
			},
			fillForm(item) {
				const { titles, primaryTexts } = item
				const _titles = titles.map(title => {
					return { title }
				})
				const _primaryTexts = primaryTexts.map(primaryText => {
					return { primaryText }
				})
				this.form = {
					...Lodash.cloneDeep(InitialForm),
					...item,
					titles: _titles.length > 0 ? _titles : [{title: ''}],
					primaryTexts: _primaryTexts.length > 0 ? _primaryTexts : [{primaryText: ''}]
				}
			},
			bidChanged(val) {
				// bid: 1.00~100.00  
				if (val === '') {
					this.form.bid = val
					return
				}
				if (/^([1-9][0-9]{0,1}(\.\d{0,2})?|100(\.0{0,2})?)$/.test(val)) {
					this.form.bid = val
					console.log('bidChanged')
				}
			},
			roasChanged(val) {
				// roas: 0.000~10.000
				if (val === '') {
					this.form.roas = val
					return
				}
				if (/^([0-9](\.\d{0,3})?|10(\.0{0,3})?)$/.test(val)) {
					this.form.roas = val
					console.log('roasChanged')
				}
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = Lodash.cloneDeep(InitialForm)
				}
			}
		}
	}
</script>

<style>
</style>
