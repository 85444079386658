

	export default {
		data() {
			return {
				pin: false,
				origin: -1,
				multipleSelection: [],
				originSelection: []
			}
		},
		mounted() {
			window.addEventListener('keydown', (code) => {
				if (code.keyCode === 16 && code.shiftKey) {
					this.originSelection = [...this.multipleSelection]
					this.pin = true
				}
			})
			window.addEventListener('keyup', (code) => {
				if (code.keyCode === 16) {
					this.pin = false
					this.origin = -1
				}
			})
		},
		unmounted() {
			window.removeEventListener('keydown')
			window.removeEventListener('keyup')
		},
		methods: {
			rowSelectable(row, index) {
				return true
			},
			handleSelectionChange(val) {
				this.multipleSelection = val
				this.$emit('handleSelectionChange', val)
			},
			pinSelect(selection, currentRow) {
				if (this.pin) {
					if (this.origin === -1) {
						this.origin = this.tableData.findIndex(v => v.id === currentRow.id)
					} else {
						this.$nextTick(() => {
							this.$refs.multipleTable.clearSelection()
							const endIndex = this.tableData.findIndex(v => v.id === currentRow.id)
							const minIndex = Math.min(this.origin, endIndex)
							const maxIndex = Math.max(this.origin, endIndex)
							for (var i = minIndex; i <= maxIndex; i++) {
								const row = this.tableData[i]
								if (this.rowSelectable(row)) {
									this.$refs.multipleTable.toggleRowSelection(row, true)
								}
							}
							this.originSelection.forEach(row => {
								this.$refs.multipleTable.toggleRowSelection(row, true)
							})
						})
					}
				}
			},
			cellClassName({row, column, rowIndex, columnIndex}) {
				if (columnIndex === 0) {
					const index = this.multipleSelection.findIndex(v => v.id === row.id) + 1
					return 'table-selected-stack-list-' + index
				}
				return ''
			}
		}
	}
